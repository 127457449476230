.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  padding: 5vh;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;  
}

.line {
  border-top: 2px solid #000000;
  margin: 20px 0;
}

.button-style {
  font-size: 1.5vh;
  margin-top: 15px;
  width: 22vw;
  margin-right: 1vw;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 8px;
  cursor: pointer;
}

.button-style-brand {
  font-size: 95%;
  width: 15vw;
  margin-left: 1vw;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 8px;
  cursor: pointer;
}

.select-and-button-container {
  display: flex; 
  align-items: center; 
  margin: 0 auto;
}

.button-style-send {
  font-size: 1.5vh; 
  margin-left: 45vw;
  width: 15vw;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 8px;
  cursor: pointer;
  margin-top: 1vh;
  margin-bottom: -1vh;
}

.button-style-lower {
  font-size: 15px;
  margin: 0 auto;
  width: 8vw;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 8px;
  cursor: pointer;
}

.select-box {
  font-size: 15px;
  width: 60vw;
  background-color: white;
  border: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 0.5em;
  margin: 0 auto;
}

.select-box:focus {
  outline: none;
}

.select-box-brand {
  font-size: 15px;
  width: 44vw;
  background-color: white;
  border: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 0.5em;
  margin: 0 auto;
}

.select-box-brand:focus {
  outline: none;
}

.form-label {
  font-size: 1.8vh;
  display: flex;
  width: 60vw;
  padding: 1.5vh;
  margin: 0 auto;
}

.form-label-info {
  font-size: 15px;
  width: 60vw;
  padding: 1.5vh;
  margin: 0 auto;
}

.input-box {
  font-size: 15px;
  margin-top: 1.5vh;
  width: 59.2vw;
  background-color: white;
  border: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 0.5em;
  margin: 0 auto;
}

.input-box:focus {
  outline: none;
}

.input-box-double {
  font-size: 15px;
  width: 25vw;
  background-color: white;
  border: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 0.5em;
  
}

.input-box-double:focus {
  outline: none;
}