.flowchart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 5vh;
}

.box {
    width: 10vw;
    height: 8vh;
    font-size: 0.9vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    border: none;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.input {
    background-color: #eceaea;
}

.output {
    background-color: #eceaea;
}

.arrow {
    width: 0;
    height: 0;
    border-top: 2vh solid transparent;
    border-bottom: 2vh solid transparent;
    border-left: 2vh solid black;
    margin: 2vw;
}