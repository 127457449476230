.info-button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgb(230, 230, 230);
    color: black;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    outline: none;
    margin-left: 2vh;

}

.info-text {
    margin-left: 1vh;
    padding: 5px;
    background-color: #f1f1f1;
    border-radius: 5px;
    font-size: 1.2vh;

}

.info-button-container {
    display: flex;
    align-items: center;
}